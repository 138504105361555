import html from './timesheet-container.html';
import lang from './timesheet-container.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';
import moment from 'moment';
import timesheet_tab from './timesheet-tab.js';
import projectsheet_tab from './components/projectsheet-tab.js';


export default {
    props: {
        pid: Number,
        date: Object,
        impersonificationMode: Boolean,
    },

    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.loadDataOnCreate, 
        mixins.module_name,
    ],

    components: {
        'timesheet-tab': timesheet_tab,
    },

    data: function() {
        return {
            contracts: [],
        };
    },

    methods: {
        load() {
            //this.$emit("change", {timesheet_busy: true});
            server.request(
                this.module_name, 'contracts-get', {pid:this.pid, year:this.date.year(), month:this.date.month()+1}, null,
                (data)=>{
                    this.contracts = data;
                }
            ).then(() => {
                //this.$emit("change", {timesheet_busy: false});
            });
        },

    },


    watch: {
        // reload data whenever date or pid change
        'pid': function() {this.load();},
        'date': function() {this.load();},  
    }
};

 
